import React from 'react';
import {route, compose, mount} from 'navi';
import {Helmet} from 'react-navi-helmet-async';
import Navigation from '@2021-mom-web/components/src/navigation';
import { Link } from 'react-navi';
import {ReactComponent as Logo} from '../../assets/missofmiss.svg';

export default compose(
    mount({
        '/2021-03-01': route({
            getView: ({context}) => (
                <>
                    <Helmet>
                        <meta content={`${process.env.REACT_APP_URL}`} property="og:url"/>
                        <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image"/>
                    </Helmet>
                    <App/>
                </>
            ),
            title: process.env.REACT_APP_WEBSITE_NAME,
        }),
    }),
);

const App = () => {
    return <div id="profiles">
        <Helmet>
            <meta content={`表彰式に関する注意事項 | ${process.env.REACT_APP_WEBSITE_NAME}`} property="og:title"/>
            <meta content={`${process.env.REACT_APP_URL}/news/2021-03-01`} property="og:url"/>
        </Helmet>
        <nav className="navbar is-black" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item" href="/">
                    <Logo/>
                </Link>
            </div>
        </nav>
        <Navigation
            additional={
                <li>
                    <a href="https://mrofmr.jp/" target="_blank">
              <span className="icon is-small">
                <i className="fas fa-external-link-alt" aria-hidden="true"/>
              </span>
                        <span>MR OF MR</span>
                    </a>
                </li>
            }
        />
        <section className="section as-profile is-missofmiss">
            <div className="container">
                <div className="tags"><span className="tag">イベント情報</span></div>
                <div className="content"><h3>表彰式に関する注意事項</h3>
                    <h3 id="■-無観客開催における禁止事項について">■ 無観客開催における禁止事項について</h3>
                    <p>新型コロナウィルスの感染拡大防止のため、以下についてご協力くださいますようお願い申し上げます。</p>
                    <ul>
                        <li>本イベントにおける会場付近での入り待ち、出待ち行為の禁止</li>
                        <li>差し入れの持ち込み、イベント出演者や関係者へ手渡し行為の禁止</li>
                    </ul>
                    <h3 id="■-ファイナリストへのプレゼント・差し入れについて">■ ファイナリストへのプレゼント・差し入れについて</h3>
                    <p>当日のプレゼント・差し入れの受付につきましても、実施を見送らせていただきます。</p><h3 id="■-祝い花について">■ 祝い花について</h3>
                    <p>スタンド花等の受付につきましても、実施を見送らせていただきます。</p><br/><br/><br/>
                    <p>上記、大変恐縮ではございますが、ご理解いただきますよう何卒よろしくお願い申し上げます。</p>
                    </div>
            </div>
        </section>
        <footer className="footer has-background-black is-padding-bottom-3">
            <div className="content has-text-centered">
                <Logo width={150}/>
                <p className="is-size-7 mt-3">Copyright © 2020 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
            </div>
            <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope"/>
          </span>
                <span>お問い合わせ</span>
            </a>
        </footer>
    </div>;
};
