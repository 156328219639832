import React from 'react';
import classNames from 'classnames';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import History from '@2021-mom-web/components/src/history';
import List from '@2021-mom-web/components/src/list';
import Card from '@2021-mom-web/components/src/card';
import News from '@2021-mom-web/components/src/news';
import Navigation from '@2021-mom-web/components/src/navigation';
import Schedule from '@2021-mom-web/components/src/schedule';
import Announcement from '@2021-mom-web/components/src/announcement';
import Entry from '@2021-mom-web/components/src/types/entry';
import {MediaBox, MediaBoxes} from "@2021-mom-web/components/src/mediabox";
import history from '../../json/history.json';
import news from '../../json/news.json';
import { ReactComponent as Blur } from '../../assets/blur.svg';
import { ReactComponent as LogoTop } from '../../assets/missofmiss.svg';
import { ReactComponent as Logo2020 } from '../../assets/logo_2020.svg';
import { ReactComponent as Mrcolle } from '../../assets/mrcolle.svg';
import { ReactComponent as Misscolle } from '../../assets/misscolle.svg';
import { ReactComponent as Ray } from '../../assets/ray.svg';
import { ReactComponent as Cancam } from '../../assets/cancam.svg';
import { ReactComponent as Datetime } from '../../assets/datetime.svg';
import { ReactComponent as Rize } from '../../assets/rize.svg';
import { ReactComponent as MensRize } from '../../assets/mensrize.svg';
import { ReactComponent as Anniversaire } from '../../assets/anniversaire.svg';
import { ReactComponent as Heather } from '../../assets/heather.svg';
import { ReactComponent as Creaub } from '../../assets/creaub.svg';
import { ReactComponent as Nowme } from '../../assets/nowme.svg';
import { list } from '../../json/entry.json';
import loadingImage from '../../assets/loading.png';

export default compose(
  mount({
    '/': route({
      getView: ({ context }) => (
        <>
          <Helmet>
            <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
            <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
          </Helmet>
          <App />
        </>
      ),
      title: process.env.REACT_APP_WEBSITE_NAME,
    }),
  }),
);

const App = () => {
  return (
    <div id="top">
      <section className="hero is-fullheight is-black">
        <div className="hero-cover">
          <figure className="blur">
            <Blur />
          </figure>
        </div>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-spaced mb-6">
              <LogoTop style={{ maxWidth: 600, maxHeight: 210 }} />
            </h1>
            <p className="subtitle">
              <Datetime />
            </p>
          </div>
        </div>
        <div className="hero-foot is-relative has-text-centered">
          <Announcement href="#award">
              グランプリ、各賞が決定しました。
          </Announcement>
          <Navigation
            additional={
              <li>
                <a href="https://mrofmr.jp/" target="_blank">
                  <span className="icon is-small">
                    <i className="fas fa-external-link-alt" aria-hidden="true" />
                  </span>
                  <span>MR OF MR</span>
                </a>
              </li>
            }
          />
        </div>
      </section>
      {/*
        Award
      */}
      <section id="award" className="section has-background-gold-stripe">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">AWARD</h1>
            <h2 className="subtitle">受賞者一覧</h2>
          </div>
          {/* グランプリ */}
          <div className="mb-6">
            <h1 className="title has-text-link is-size-3 as-section-title has-text-gold has-text-weight-bold">グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${13}`}
                  entry={{
                    "id" : 13,
                    "mysta" : "956654",
                    "showroom" : "",
                    "college" : "東京大学",
                    "name" : "神谷 明采",
                    "furigana" : "かみや あさ",
                    "hometown" : "埼玉県",
                    "grade" : 1,
                    "department" : "教養学部文科2類",
                    "twitter" : "miss01todai2020",
                    "instagram" : "asa_kamiya",
                    "hobby" : "美味しいものを食べること",
                    "skill" : "軟体",
                    "dream" : "",
                    "enthusiasm" : "笑顔と感謝を常に忘れず、頑張ります",
                    "circle" : "ダンス系",
                    "magazine" : "CanCam",
                    "birthday" : "2000/04/19",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7
                    ],
                    "finalist" : true
                  }}
              />
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">準グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${1}`}
                  entry={{
                    "id" : 1,
                    "mysta" : "956638",
                    "showroom" : "",
                    "college" : "学習院大学",
                    "name" : "小川 奏",
                    "furigana" : "おがわ かな",
                    "hometown" : "青森県",
                    "grade" : 3,
                    "department" : "文学部",
                    "twitter" : "missg2020_no2",
                    "instagram" : "missg2020_no2",
                    "hobby" : "料理、野球観戦、美術館巡り",
                    "skill" : "書道、箏、剣道",
                    "dream" : "アナウンサー",
                    "enthusiasm" : "誰よりも楽しみ、笑顔で頑張ります！",
                    "circle" : "野球サークルJOYTHULL",
                    "magazine" : "CanCam、ray、美人百花",
                    "birthday" : "1999/11/05",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${3}`}
                  entry={{
                    "id" : 3,
                    "mysta" : "956641",
                    "showroom" : "",
                    "college" : "関西学院大学",
                    "name" : "山本 瑠香",
                    "furigana" : "やまもと るか",
                    "hometown" : "和歌山県",
                    "grade" : 2,
                    "department" : "経済学部",
                    "twitter" : "__ruka1010__",
                    "instagram" : "ruka_1010_team8",
                    "hobby" : "料理、旅行、カフェ巡り",
                    "skill" : "料理",
                    "dream" : "アナウンサー",
                    "enthusiasm" : "楽しむことを1番にファンの皆さんと一緒に上を目指してがんばります！",
                    "circle" : "",
                    "magazine" : "CanCam、bis",
                    "birthday" : "2000/10/10",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10
                    ],
                    "finalist" : true
                  }}
              />
            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="mb-6">
            <h1 className="title is-size-5 as-section-title has-text-weight-bold">審査員特別賞</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${5}`}
                  entry={{
                    "id" : 5,
                    "mysta" : "956643",
                    "showroom" : "",
                    "college" : "関西学院大学",
                    "name" : "松本 美紅",
                    "furigana" : "まつもと みく",
                    "hometown" : "兵庫県",
                    "grade" : 3,
                    "department" : "商学部",
                    "twitter" : "mckg2020_no4",
                    "instagram" : "mckg2020_no4",
                    "hobby" : "YouTubeを見る",
                    "skill" : "ピアノ",
                    "dream" : "幸せに暮らす",
                    "enthusiasm" : "皆さんの応援に応えられるよう、精一杯頑張ります。",
                    "circle" : "",
                    "magazine" : "",
                    "birthday" : "1999/09/23",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${6}`}
                  entry={{
                    "id" : 6,
                    "mysta" : "956646",
                    "showroom" : "",
                    "college" : "同志社大学",
                    "name" : "木原 渚",
                    "furigana" : "きはら なぎさ",
                    "hometown" : "兵庫県",
                    "grade" : 2,
                    "department" : "経済学部",
                    "twitter" : "misscd2020_kn01",
                    "instagram" : "nagisa_k3",
                    "hobby" : "寝ること，おしゃべり",
                    "skill" : "",
                    "dream" : "",
                    "enthusiasm" : "マイペースに頑張ります。",
                    "circle" : "お煎茶サークル",
                    "magazine" : "",
                    "birthday" : "2001/03/16",
                    "images" : [
                    1,
                    2,
                    3,
                    4,
                    5,
                    6
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${11}`}
                  entry={{
                    "id" : 11,
                    "mysta" : "956652",
                    "showroom" : "",
                    "college" : "立命館大学",
                    "name" : "仲間 由依",
                    "furigana" : "なかつま ゆい",
                    "hometown" : "奈良県",
                    "grade" : 2,
                    "department" : "情報理工学部",
                    "twitter" : "mcr2020_06ny",
                    "instagram" : "mcr2020_06ny",
                    "hobby" : "ラーメン巡り、神社巡り",
                    "skill" : "早起き",
                    "dream" : "自分と自分の好きな人達を幸せにすること",
                    "enthusiasm" : "楽しみつつ、頑張ります",
                    "circle" : "",
                    "magazine" : "Ray",
                    "birthday" : "2000/09/16",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10
                    ],
                    "finalist" : true
                  }}
              />
            </div>
          </div>
          {/* ミスリゼクリニック賞/ミスリゼウォーク賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold">ミス<br className="is-hidden-tablet" />リゼクリニック賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${3}`}
                  entry={{
                    "id" : 3,
                    "mysta" : "956641",
                    "showroom" : "",
                    "college" : "関西学院大学",
                    "name" : "山本 瑠香",
                    "furigana" : "やまもと るか",
                    "hometown" : "和歌山県",
                    "grade" : 2,
                    "department" : "経済学部",
                    "twitter" : "__ruka1010__",
                    "instagram" : "ruka_1010_team8",
                    "hobby" : "料理、旅行、カフェ巡り",
                    "skill" : "料理",
                    "dream" : "アナウンサー",
                    "enthusiasm" : "楽しむことを1番にファンの皆さんと一緒に上を目指してがんばります！",
                    "circle" : "",
                    "magazine" : "CanCam、bis",
                    "birthday" : "2000/10/10",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold">ミス<br className="is-hidden-tablet" />リゼウォーク賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${13}`}
                  entry={{
                    "id" : 13,
                    "mysta" : "956654",
                    "showroom" : "",
                    "college" : "東京大学",
                    "name" : "神谷 明采",
                    "furigana" : "かみや あさ",
                    "hometown" : "埼玉県",
                    "grade" : 1,
                    "department" : "教養学部文科2類",
                    "twitter" : "miss01todai2020",
                    "instagram" : "asa_kamiya",
                    "hobby" : "美味しいものを食べること",
                    "skill" : "軟体",
                    "dream" : "",
                    "enthusiasm" : "笑顔と感謝を常に忘れず、頑張ります",
                    "circle" : "ダンス系",
                    "magazine" : "CanCam",
                    "birthday" : "2000/04/19",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7
                    ],
                    "finalist" : true
                  }}
              />
            </div>
          </div>
          {/* アナトレ賞/テレビ朝日アスク賞/ANNIVERSAIRE賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title has-text-weight-bold"><br className="is-hidden-tablet" />アナトレ賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${11}`}
                  entry={{
                    "id" : 11,
                    "mysta" : "956652",
                    "showroom" : "",
                    "college" : "立命館大学",
                    "name" : "仲間 由依",
                    "furigana" : "なかつま ゆい",
                    "hometown" : "奈良県",
                    "grade" : 2,
                    "department" : "情報理工学部",
                    "twitter" : "mcr2020_06ny",
                    "instagram" : "mcr2020_06ny",
                    "hobby" : "ラーメン巡り、神社巡り",
                    "skill" : "早起き",
                    "dream" : "自分と自分の好きな人達を幸せにすること",
                    "enthusiasm" : "楽しみつつ、頑張ります",
                    "circle" : "",
                    "magazine" : "Ray",
                    "birthday" : "2000/09/16",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title has-text-weight-bold">テレビ朝日<br className="is-hidden-tablet" />アスク賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${13}`}
                  entry={{
                    "id" : 13,
                    "mysta" : "956654",
                    "showroom" : "",
                    "college" : "東京大学",
                    "name" : "神谷 明采",
                    "furigana" : "かみや あさ",
                    "hometown" : "埼玉県",
                    "grade" : 1,
                    "department" : "教養学部文科2類",
                    "twitter" : "miss01todai2020",
                    "instagram" : "asa_kamiya",
                    "hobby" : "美味しいものを食べること",
                    "skill" : "軟体",
                    "dream" : "",
                    "enthusiasm" : "笑顔と感謝を常に忘れず、頑張ります",
                    "circle" : "ダンス系",
                    "magazine" : "CanCam",
                    "birthday" : "2000/04/19",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title "><br className="is-hidden-tablet" />ANNIVERSAIRE賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${6}`}
                  entry={{
                    "id" : 6,
                    "mysta" : "956646",
                    "showroom" : "",
                    "college" : "同志社大学",
                    "name" : "木原 渚",
                    "furigana" : "きはら なぎさ",
                    "hometown" : "兵庫県",
                    "grade" : 2,
                    "department" : "経済学部",
                    "twitter" : "misscd2020_kn01",
                    "instagram" : "nagisa_k3",
                    "hobby" : "寝ること，おしゃべり",
                    "skill" : "",
                    "dream" : "",
                    "enthusiasm" : "マイペースに頑張ります。",
                    "circle" : "お煎茶サークル",
                    "magazine" : "",
                    "birthday" : "2001/03/16",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6
                    ],
                    "finalist" : true
                  }}
              />
            </div>
          </div>
          {/* La Crème au beurre賞/Now me. by RUNWAY cannel賞/Heather賞 */}
          <div className="columns is-centered is-multiline is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">La Crème <br />au beurre賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${8}`}
                  entry={{
                    "id" : 8,
                    "mysta" : "956648",
                    "showroom" : "",
                    "college" : "桜美林大学",
                    "name" : "犬塚 花菜子",
                    "furigana" : "いぬつか かなこ",
                    "hometown" : "福岡県",
                    "grade" : 2,
                    "department" : "芸術文化学群",
                    "twitter" : "ms2_obirin20",
                    "instagram" : "ms2_obirin20",
                    "hobby" : "旅行",
                    "skill" : "サッカー、フラダンス",
                    "dream" : "女優",
                    "enthusiasm" : "将来の夢へまた1歩近づけるよう感謝の気持ちを忘れず精一杯頑張ります",
                    "circle" : "フットサル",
                    "magazine" : "",
                    "birthday" : "2000/05/16",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9,
                      10
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title">Now me. <br /><span className="is-size-7">by RUNWAY channel</span>賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${5}`}
                  entry={{
                    "id" : 5,
                    "mysta" : "956643",
                    "showroom" : "",
                    "college" : "関西学院大学",
                    "name" : "松本 美紅",
                    "furigana" : "まつもと みく",
                    "hometown" : "兵庫県",
                    "grade" : 3,
                    "department" : "商学部",
                    "twitter" : "mckg2020_no4",
                    "instagram" : "mckg2020_no4",
                    "hobby" : "YouTubeを見る",
                    "skill" : "ピアノ",
                    "dream" : "幸せに暮らす",
                    "enthusiasm" : "皆さんの応援に応えられるよう、精一杯頑張ります。",
                    "circle" : "",
                    "magazine" : "",
                    "birthday" : "1999/09/23",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      9
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase"><br />Heather賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${13}`}
                  entry={{
                    "id" : 13,
                    "mysta" : "956654",
                    "showroom" : "",
                    "college" : "東京大学",
                    "name" : "神谷 明采",
                    "furigana" : "かみや あさ",
                    "hometown" : "埼玉県",
                    "grade" : 1,
                    "department" : "教養学部文科2類",
                    "twitter" : "miss01todai2020",
                    "instagram" : "asa_kamiya",
                    "hobby" : "美味しいものを食べること",
                    "skill" : "軟体",
                    "dream" : "",
                    "enthusiasm" : "笑顔と感謝を常に忘れず、頑張ります",
                    "circle" : "ダンス系",
                    "magazine" : "CanCam",
                    "birthday" : "2000/04/19",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7
                    ],
                    "finalist" : true
                  }}
              />
            </div>
          </div>
          {/* mysta賞/MISS COLLE賞 */}
          <div className="columns is-centered is-mobile mb-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">mysta賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${6}`}
                  entry={{
                    "id" : 6,
                    "mysta" : "956646",
                    "showroom" : "",
                    "college" : "同志社大学",
                    "name" : "木原 渚",
                    "furigana" : "きはら なぎさ",
                    "hometown" : "兵庫県",
                    "grade" : 2,
                    "department" : "経済学部",
                    "twitter" : "misscd2020_kn01",
                    "instagram" : "nagisa_k3",
                    "hobby" : "寝ること，おしゃべり",
                    "skill" : "",
                    "dream" : "",
                    "enthusiasm" : "マイペースに頑張ります。",
                    "circle" : "お煎茶サークル",
                    "magazine" : "",
                    "birthday" : "2001/03/16",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6
                    ],
                    "finalist" : true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="mb-6">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title is-no-uppercase">MISS COLLE賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  className="is-missofmiss"
                  loadingImage={loadingImage}
                  href={`/profiles/${4}`}
                  entry={{
                    "id" : 4,
                    "mysta" : "956642",
                    "showroom" : "",
                    "college" : "関西大学",
                    "name" : "加藤 千絢",
                    "furigana" : "かとう ちあや",
                    "hometown" : "兵庫県",
                    "grade" : 2,
                    "department" : "社会安全学部",
                    "twitter" : "kandai20miss_3",
                    "instagram" : "kandai20miss_3",
                    "hobby" : "おそ松さんのアニメを観ること",
                    "skill" : "円周率50桁暗唱",
                    "dream" : "変わりたい、でも勇気が出ないという思いがある方の、一歩踏み出すきっかけになりたいです。",
                    "enthusiasm" : "ここまで来たらやり切るのみです！",
                    "circle" : "",
                    "magazine" : "",
                    "birthday" : "2000/03/06",
                    "images" : [
                      1,
                      2,
                      3,
                      4,
                      5,
                      6
                    ],
                    "finalist" : true
                  }}
              />
            </div>
          </div>
        </div>
      </section>



      {/*
        Entries
      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">FINALIST</h1>
            <h2 className="subtitle">ファイナリスト</h2>
          </div>
          <div className="columns is-multiline is-mobile mb-6">
            <List list={list} finalist>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile')}
                    key={item.id}
                  >
                    <Card
                      className="is-missofmiss"
                      loadingImage={loadingImage}
                      href={`/profiles/${item.id}`}
                      entry={item}
                    />
                  </div>
                ))
              }
            </List>
          </div>
        </div>
      </section>
      {/*
        Schedule
      */}
      <section id="schedule" className="section has-background-gradient">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">Schedule</h1>
            <h2 className="subtitle mb-3">日本一のミスキャンパスが決まるまで</h2>
            <p className="is-size-7 has-text-white has-text-centered">
              ※ スケジュールは予告なく変更されることがあります。
            </p>
          </div>
          <Schedule />
        </div>
      </section>
      {/*
        Information
      */}
      <section id="information" className="section">
        <div className="container" style={{ maxWidth: 740 }}>
          <div className="mb-6">
            <h1 className="title as-section-title">Event</h1>
            <h2 className="subtitle">イベント情報</h2>
          </div>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-3">
            MISS OF MISS CAMPUS QUEEN CONTEST 2021 supported by リゼクリニック
          </p>
          <p className="has-text-centered is-size-6-mobile is-size-5 has-text-weight-bold mb-6">
            MR OF MR CAMPUS CONTEST 2021 supported by メンズリゼ
          </p>
          <div className="is-margin-bottom-3 is-margin-top-2">
            <h1 className="title is-gray is-size-6 has-text-weight-bold as-section-title">MC</h1>
            <h2 className="subtitle"></h2>
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered">
              <p className="is-size-6 is-margin-bottom-1">
                <span className="is-size-4 has-text-weight-bold">NON STYLE</span>
                <br />
              </p>
              <div id="mc" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480">
                    <img src="/image/nonstyle.jpg" width="320" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6 mt-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered">
              <p className="is-size-6 is-margin-bottom-1">
                <span className="is-size-5 has-text-weight-bold">高見 侑里</span>
                <br className="is-hidden-tablet" />
                <span className="is-size-7-mobile">（フリーアナウンサー）</span>
                <br />
                <br className="is-hidden-mobile" />
                <span className="is-size-7-mobile">
                  MISS OF MISS CAMPUS QUEEN CONTEST <br className="is-hidden-tablet" />
                  2008 グランプリ
                </span>
              </p>
              <div id="mc" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480">
                    <img src="/image/mc.jpg" width="320" />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">
                    12年前に参加させていただいたMISS OF MISS CAMPUS QUEEN
                    CONTESTにこうしてまた携わらせていただき、嬉しさと懐かしい気持ちでいっぱいです。
                    <br />
                    エントリーされたお一人お一人が輝けるように、またこうした状況で今年も開催できたことに感謝しながら、皆様と温かな時間を共有できるよう真摯に務めてさせていただきたいと思います。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="is-margin-bottom-3 mt-6">
            <h1 className="title is-gray is-size-6 has-text-weight-bold as-section-title">ゲスト</h1>
            <h2 className="subtitle"></h2>
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className="column is-8-tablet is-10-mobile has-text-centered">
              <p className="is-size-6 is-margin-bottom-1">
                <span className="is-size-5 has-text-weight-bold">山賀 琴子</span>
                <br className="is-hidden-tablet" />
                <span className="is-size-7-mobile">（モデル・ブランドディレクター）</span>
                <br />
                <br className="is-hidden-mobile" />
                <span className="is-size-7-mobile">
                  MISS OF MISS CAMPUS QUEEN CONTEST <br className="is-hidden-tablet" />
                  2016 グランプリ
                </span>
              </p>
              <div id="guest" className="card">
                <div className="card-image">
                  <figure className="image is-max-480x480">
                    <img src="/image/guest.jpg" width="320" />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content has-text-left is-size-7-mobile">
                    MISS OF MISS CAMPUS QUEEN
                    CONTESTに参加してから4年が経ち、今回また携わらせていただくことができとても嬉しく思います。
                    <br />
                    出場者の皆さまにお会いできることを楽しみにしています。
                    <br />
                    そして皆さまと素晴らしい時間を過ごせるように精一杯務めたいと思っていますのでよろしくお願い致します。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table is-fullwidth as-information mt-6">
            <tbody>
              <tr>
                <th className="is-narrow">会場</th>
                <td>
                  <a
                    className="has-text-grey-dark"
                    style={{ textDecoration: 'underline' }}
                    href="https://www.ex-theater.com/"
                    target="_blank"
                  >
                    EX THEATER ROPPONGI
                  </a>{' '}
                  (東京都港区西麻布1-2-9)
                </td>
              </tr>
              <tr>
                <th className="is-narrow">日時</th>
                <td>2021年3月2日(火)</td>
              </tr>
              <tr>
                <th className="is-narrow">オンライン配信</th>
                <td><a
                    className="has-text-grey-dark"
                    style={{ textDecoration: 'underline', wordBreak: "break-all" }}
                    href="https://17.live/profile/r/14269982" target="_blank">https://17.live/profile/r/14269982</a></td>
              </tr>
              <tr>
                <th className="is-narrow">主催</th>
                <td>学生団体MARKS / 株式会社エイジ・エンタテインメント</td>
              </tr>
              <tr>
                <th className="is-narrow">企画制作</th>
                <td>
                  <div className="images">
                    <a href="https://misscolle.com/" target="_blank">
                      <figure className="image mt-0">
                        <Misscolle />
                      </figure>
                    </a>
                    <a href="https://mrcolle.com/" target="_blank">
                      <figure className="image mt-0">
                        <Mrcolle />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">特別協賛</th>
                <td>
                  <div className="images">
                    <a href="https://www.rizeclinic.com/" target="_blank">
                      <figure className="image">
                        <Rize height={50} />
                      </figure>
                    </a>
                    <a href="https://www.mens-rize.com/" target="_blank">
                      <figure className="image">
                        <MensRize height={50} />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">特別協力</th>
                <td>
                  <div id="sponsors" className="images">
                    <a href="http://anatore.fujitv.co.jp/" target="_blank">
                      <figure id="anatore" className="image mt-0 mb-5">
                        <img src="/image/anatore.png" />
                      </figure>
                    </a>
                    <a href="https://www.tv-asahi-ask.co.jp/" target="_blank">
                      <figure id="ask" className="image mt-0 mb-6">
                        <img src="/image/ask.png" />
                      </figure>
                    </a>
                    <a href="https://cancam.jp/" target="_blank">
                      <figure id="cancam" className="image mt-0 mb-6">
                        <Cancam height={40} />
                      </figure>
                    </a>
                    <a href="https://www.mensnonno.jp/" target="_blank">
                      <figure id="mensnonno" className="image mt-0 mb-6">
                        <img src="/image/mensnonno.png" />
                      </figure>
                    </a>
                    <a href="https://nonno.hpplus.jp/" target="_blank">
                      <figure id="nonno" className="image mt-0 mb-6">
                        <img src="/image/nonno.png" />
                      </figure>
                    </a>
                    <a href="https://ray-web.jp/" target="_blank">
                      <figure id="ray" className="image mt-0 mb-6">
                        <Ray height={45} />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">協賛</th>
                <td>
                  <div className="images">
                    <a href="https://www.anniversaire.co.jp/" target="_blank">
                      <figure id="anniversaire" className="image mt-0 mb-6">
                        <Anniversaire height={45} />
                      </figure>
                    </a>
                    <a href="https://creab.official.ec/" target="_blank">
                      <figure id="creaub" className="image mt-0 mb-6">
                        <Creaub height={40} />
                      </figure>
                    </a>
                    <a href="https://runway-webstore.com/instagram/nowme/" target="_blank">
                      <figure id="nowme" className="image mt-1 mb-6">
                        <Nowme height={40} />
                      </figure>
                    </a>
                    <a href="https://www.dot-st.com/heather/" target="_blank">
                      <figure id="heather" className="image mt-0 mb-6">
                        <Heather height={40} />
                      </figure>
                    </a>
                    <a href="https://www.mysta.tv/" target="_blank">
                      <figure id="mysta" className="image mt-0 mb-6">
                        <img src="/image/mysta.png" height={40} style={{maxHeight: 30, marginTop: 4}} />
                      </figure>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="is-narrow">日程</th>
                <td>
                  全国予選：2021/2/8(月)～2021/2/14(日)<br />
                  <br />
                  決勝：2021/2/21(日)～2021/2/27(土)<br />
                  <br />
                  表彰式：2021/3/2(火)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/*
        History
      */}
      <section id="history" className="section is-missofmiss">
        <div className="container">
          <div className="mb-6">
            <h1 className="title as-section-title">History</h1>
            <h2 className="subtitle">ミスオブミスの18年の歴史</h2>
          </div>
          <History json={history} />
        </div>
      </section>
      {/*
        Media
      */}
      <section id="media" className="section">
        <div className="container" style={{maxWidth: 780}}>
          <div className="mb-6">
            <h1 className="title as-section-title">Media</h1>
            <h2 className="subtitle">掲載メディア</h2>
          </div>
          <MediaBoxes>
            <div style={{ maxWidth: 250 }}>
              <MediaBox
                  href="https://mdpr.jp/news/detail/2429554"
                  src="https://img-mdpr.freetls.fastly.net/article/r3wC/nm/r3wC16kd1fg-_J65xBn2bTimQPpU6KtQgGjxLIZ6Fio.jpg?width=700&disable=upscale&auto=webp"
                  title="[モデルプレス]日本一のミスキャンパスを決めるコンテスト、全国予選出場者決定＜MISS OF MISS CAMPUS QUEEN CONTEST 2021＞"
                  media="modelpress"
                  datetext="2021.02.08"
                  icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <MediaBox
                  href="https://mdpr.jp/news/2394445"
                  src="https://img-mdpr.freetls.fastly.net/article/PfUk/nm/PfUkwwTLcoRt7-d90MRoPSQq3LEXxCiXy7vIQSc0sto.jpg?width=700&disable=upscale&auto=webp"
                  title="[モデルプレス]日本一のミスキャンパスを決めるコンテスト、第1弾出場者発表＜MISS OF MISS CAMPUS QUEEN CONTEST 2021＞"
                  media="modelpress"
                  datetext="2021.01.15"
                  icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <MediaBox
                  href="https://thetv.jp/news/detail/1017512/"
                  src="https://thetv.jp/i/nw/1017512/10151516.jpg?w=615"
                  title='[ザ・テレビジョン]全国のミスキャンパスの中から"日本一美しい大学生"を決定する"ミスオブミス"、第一弾出場者発表'
                  media="ザ・テレビジョン"
                  datetext="2021.01.15"
                  icon="https://pbs.twimg.com/profile_images/842775920091975680/7igeN6FE_400x400.jpg"
              />
            </div>
          </MediaBoxes>
        </div>
      </section>

      {/*
         News
      */}
      <section id="news" className="section">
        <div className="container" style={{maxWidth: 540}}>
          <div className="columns">
            <div className="column">
              <div className="mb-6">
                <h1 className="title as-section-title">News</h1>
                <h2 className="subtitle">お知らせ</h2>
              </div>
              <News json={news} />
            </div>
          </div>
        </div>
      </section>

      <nav className="navbar">
        <div className="columns is-variable is-4 is-mobile is-centered is-vcentered">
          <div className="column is-narrow">
            <figure className="image">
              <Logo2020 width={120} />
            </figure>
          </div>
          <div className="column is-narrow">
            <a
              href="https://2020.missofmiss.jp"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-size-7-mobile is-black has-text-weight-bold"
            >
              <span>昨年の様子はこちら</span>
              <span className="icon">
                <i className="fas fa-chevron-right" />
              </span>
            </a>
          </div>
        </div>
      </nav>
      <footer className="footer has-background-black is-padding-bottom-3">
        <div className="content has-text-centered">
          <LogoTop width={150} />
          <p className="is-size-7 mt-3">Copyright © 2020 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
        </div>
        <a className="button is-size-7 is-light is-dark" href="mailto:inquiry@missofmiss.jp">
          <span className="icon">
            <i className="far fa-envelope" />
          </span>
          <span>お問い合わせ</span>
        </a>
      </footer>
    </div>
  );
};
