import React, { useEffect } from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import { Link } from 'react-navi';
import { ReactComponent as Logo } from '../../assets/missofmiss.svg';

export default compose(
    mount({
        '/': route({
            getView: ({ context }) => (
                <>
                    <Helmet>
                        <meta content={`${process.env.REACT_APP_URL}`} property="og:url" />
                        <meta content={`${process.env.REACT_APP_OGP_URL}`} property="og:image" />
                    </Helmet>
                    <App />
                </>
            ),
            title: process.env.REACT_APP_WEBSITE_NAME,
        }),
    }),
);

const App = () => {
    return (
        <div id="thanks">
            <nav className="navbar is-black" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link className="navbar-item" href="/">
                        <Logo />
                    </Link>
                    <div className="navbar-item is-padding-left-0">
                        {/* TODO */}
                        <img height="28" />
                    </div>
                </div>
            </nav>
            <section className="hero is-black is-fullheight-with-navbar">
                <div className="hero-body">
                    <div className="container is-slim">
                        <p className="title is-size-5 has-text-centered">フォームのご提出を完了致しました </p>
                        <div className="notification has-text-dark is-size-7 is-padding-right-1 is-padding-left-1">
                            この度は「{process.env.REACT_APP_WEBSITE_NAME}」にお問い合わせ頂きありがとうございました。
                        </div>
                        <Link className="button is-medium has-crown is-fullwidth" href="/">
                            トップに戻る
                        </Link>
                    </div>
                </div>
                <div className="hero-foot">
                    <footer className="footer has-background-black is-padding-bottom-3">
                        <div className="content has-text-centered">
                            <Logo width={150} />
                            <p className="is-size-7 mt-3">Copyright © 2020 株式会社エイジ・エンタテインメント All Rights Reserved.</p>
                        </div>
                    </footer>
                </div>
            </section>
        </div>
    );
};
